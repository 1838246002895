import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Template from '../templates/features-template'

const FeaturesIndex = (props) => <Template {...props} />

FeaturesIndex.propTypes = {
  data: PropTypes.object.isRequired
}

export default FeaturesIndex

export const pageQuery = graphql`
  query featuresFrQuery {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: "fr-CA" } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    features: allContentfulFeature(
      filter: { node_locale: { eq: "fr-CA" } }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...FeatureLink
        }
      }
    }
  }
`
